import React from "react"
import PropTypes from "prop-types"

const Mission = ({mission}) => {
  return (
    <div>
      <div className="Mission">
        <div className="container">
          <h4 className="title">{mission.omTitle}</h4>
          <p>{mission.omDetails}</p>
        </div>
      </div>
    </div>
  )
}

Mission.propTypes = {
  mission: PropTypes.object,
}

export default Mission
