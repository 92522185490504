import React from "react"
import PropTypes from "prop-types"

const OurTeam = ({ team }) => {
  return (
    <div>
      <div className="OurTeam">
        <div className="container">
          <h4 className="title">{team.teamTitle}</h4>
          <div className="row">
            {team.teamData.map(teamData => (
              <div className="col-sm-6 col-md-4 col-lg-3" key={teamData.id}>
                <div className="teamMember">
                  <div className="MemberImg">
                    <img src={teamData.image} alt={teamData.name} />
                  </div>
                  <h5 className="memberName">{teamData.name}</h5>
                  <p className="designation">{teamData.designation}</p>
                  <span className="teamBorder"></span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

OurTeam.propTypes = {
  team: PropTypes.object,
}

export default OurTeam
