import { graphql, useStaticQuery } from "gatsby"

const AboutUsQuery = () => useStaticQuery(graphql`
query{
  aboutus:allJsonDataJson(filter:{aboutBanner:{aboutBannerTitle:{ne:null}}}){
    edges{
      node{
        aboutBanner{
          aboutBannerTitle
          aboutBannerSubTitle
          aboutBannerImage
        }
        story{
          storyTitle
          storyDetails
          storyWeImg
          storyWe
          storyWeDetails
          storyDoImg
          storyDo
          storyDoDetails
        }
        mission{
          omTitle
          omDetails
        }
        team{
          teamTitle
          teamData{
            id
            image
            name
            designation
          }
        }
      }
    }
  }
}`)

  export default AboutUsQuery