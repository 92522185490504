import React from "react"
import PropTypes from "prop-types"

const Story = ({story}) => {
  return (
    <div>
      <div className="Story">
        <div className="container">
          <h4 className="title">{story.storyTitle}</h4>
          <p>{story.storyDetails}</p>
          <div className="who-what-we">
            <div className="row">
              <div className="col-md-6">
                <div className="who_we">
                  <img src={story.storyWeImg} alt="Who We" />
                  <h4 className="who_we_title">{story.storyWe}</h4>
                  <p>{story.storyWeDetails}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="who_we">
                  <img src={story.storyDoImg} alt="What We" />
                  <h4 className="who_we_title">{story.storyDo}</h4>
                  <p>{story.storyDoDetails}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Story.propTypes = {
  story: PropTypes.object,
}

export default Story
