import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WelcomeBanner from "../components/WelcomeBanners"
import Story from "../components/AboutUs/Story"
import Mission from "../components/AboutUs/Mission"
import OurTeam from "../components/AboutUs/OurTeam"
import Aboutus from "../query/AboutusQuery"

const AboutUs = () => {
  const { aboutus } = Aboutus()
  const aboutUsData = aboutus.edges[0].node

  return (
    <Layout>
      <SEO title="AboutUs" />
      <WelcomeBanner
        bannerTitle={aboutUsData.aboutBanner.aboutBannerTitle}
        bannerSubTitle={aboutUsData.aboutBanner.aboutBannerSubTitle}
        bannerImage={aboutUsData.aboutBanner.aboutBannerImage}
      />
      <Story story={aboutUsData.story}></Story>
      <Mission mission={aboutUsData.mission}></Mission>
      <OurTeam team={aboutUsData.team}></OurTeam>
    </Layout>
  )
}

export default AboutUs
